import { useState } from 'react';

import { Card, Cell, Text as MagritteText, TextArea as MagritteTextArea } from '@hh.ru/magritte-ui';
import { type TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

interface LetterProps {
    expanded: boolean;
    expandLetter: () => void;
    value: string;
    setLetterValue: (value: string) => void;
    required: boolean;
    maxLength: number;
    isBottomSheet?: boolean;
    isModal?: boolean;
    isQuestionLetter?: boolean;
}

const TrlKeys = {
    expand: 'vacancy.response.popup.letter',
    title: 'vacancy.response.popup.letter',
    questionTitle: 'vacancy.response.popup.questionLetter',
    addLetter: 'applicant.negotiations.write',
    placeholder: 'vacancy.response.popup.letter.placeholder',
    questionPlaceholder: 'vacancy.response.popup.question.placeholder',
    letterRequired: 'vacancy.response.popup.letter.required.placeholder',
    requiredDescription: 'vacancy.response.popup.letter.requiredDescription',
    requiredDescriptionMobile: 'vacancy.response.popup.letter.requiredDescription.mobile',
    add: 'vacancy.response.popup.letter.add',
};

const Letter: TranslatedComponent<LetterProps> = ({
    expanded,
    expandLetter,
    value,
    setLetterValue,
    required,
    maxLength,
    isBottomSheet,
    isModal,
    isQuestionLetter,
    trls,
}) => {
    const [invalidLimit, setInvalidLimit] = useState(value.length > maxLength);

    if (!expanded) {
        const isCustomExpander = isBottomSheet || isModal;

        if (isCustomExpander) {
            return null;
        }

        return (
            <Card
                padding={16}
                borderRadius={16}
                stretched
                borderWidth="default"
                data-qa="vacancy-response-letter-toggle"
                onClick={expandLetter}
            >
                <Cell
                    right={
                        <MagritteText Element="span" style="accent" typography="label-2-regular">
                            {trls[TrlKeys.add]}
                        </MagritteText>
                    }
                >
                    <MagritteText Element="span" typography="label-2-regular">
                        {trls[TrlKeys.title]}
                    </MagritteText>
                </Cell>
            </Card>
        );
    }

    return (
        <MagritteTextArea
            value={value}
            elevatePlaceholder={isQuestionLetter}
            placeholder={trls[isQuestionLetter ? TrlKeys.questionPlaceholder : TrlKeys.title]}
            description={
                required && !isQuestionLetter
                    ? trls[isBottomSheet ? TrlKeys.requiredDescriptionMobile : TrlKeys.requiredDescription]
                    : ''
            }
            layout="hug"
            maxLength={maxLength}
            invalidLimit={invalidLimit}
            data-qa="vacancy-response-popup-form-letter-input"
            onValidate={({ invalidLimit }) => {
                setInvalidLimit(invalidLimit);
            }}
            onChange={(event) => {
                setLetterValue(event.target.value);
            }}
        />
    );
};

export default translation(Letter);
