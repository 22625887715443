import { SPALink } from '@hh.ru/redux-spa-middleware';
import Button, { ButtonAppearance } from 'bloko/blocks/button';
import NotificationFooter from 'bloko/blocks/notificationManager/NotificationFooter';
import NotificationHeading from 'bloko/blocks/notificationManager/NotificationHeading';
import Text from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import translation from 'src/components/translation';
import { AutoHideTime } from 'src/models/resume/resumeCommon.types';

const TrlKeys = {
    publishHeader: 'resume.publish.notify.resumeAutoHide.title',
    responseHeader: 'vacancy.view.notify.response.title',
    publishSubtitle: 'resume.publish.notify.resumeAutoHide.subtitle',
    description: 'rb.visibility.autoHideTime.prefix.notification',
    values: {
        [AutoHideTime.FiveYears]: 'rb.visibility.autoHideTime.suffix.year_5',
        [AutoHideTime.ThreeYears]: 'rb.visibility.autoHideTime.suffix.year_3',
        [AutoHideTime.TwoYears]: 'rb.visibility.autoHideTime.suffix.year_2',
        [AutoHideTime.TwelveMonths]: 'rb.visibility.autoHideTime.suffix.month_12',
        [AutoHideTime.TenMonths]: 'rb.visibility.autoHideTime.suffix.month_10',
        [AutoHideTime.EightMonths]: 'rb.visibility.autoHideTime.suffix.month_8',
    },
    button: 'vacancy.view.notify.response.resumeAutoHide.button',
};

interface ResumeAutoHideNotificationProps {
    resumeHash: string;
    autoHideTime?: AutoHideTime;
    isVacancyResponse?: boolean;
}

const ResumeAutoHideNotification: TranslatedComponent<ResumeAutoHideNotificationProps> = ({
    trls,
    resumeHash,
    autoHideTime = AutoHideTime.TwelveMonths,
    isVacancyResponse = true,
}) => {
    return (
        <>
            <NotificationHeading>
                {isVacancyResponse ? trls[TrlKeys.responseHeader] : trls[TrlKeys.publishHeader]}
            </NotificationHeading>
            <VSpacing base={1} />
            {!isVacancyResponse && (
                <>
                    <Text>{trls[TrlKeys.publishSubtitle]}</Text>
                    <VSpacing base={5} />
                </>
            )}
            <Text>{format(trls[TrlKeys.description], { '{0}': trls[TrlKeys.values[autoHideTime]] })}</Text>
            <VSpacing base={2} />
            <NotificationFooter>
                <Button
                    Element={SPALink}
                    to={`/applicant/resumes/edit/visibility?resume=${resumeHash}`}
                    appearance={ButtonAppearance.Outlined}
                >
                    {trls[TrlKeys.button]}
                </Button>
            </NotificationFooter>
        </>
    );
};

export default {
    Element: translation(ResumeAutoHideNotification),
    kind: 'ok',
    autoCloseDelay: 10000,
    autoClose: true,
};
