import { RefObject, useCallback } from 'react';

import {
    Action,
    Card,
    Cell,
    CellText,
    NavigationBar,
    Text,
    VSpacingContainer,
    findStaticDataFetcherItemByValue,
    StaticDataFetcherItem,
    useStaticDataProvider,
} from '@hh.ru/magritte-ui';
import { RenderOptionType, Select } from '@hh.ru/magritte-ui-select';
import { ChevronDownOutlinedSize24, ChevronUpOutlinedSize24, CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { type TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import type { ResponseStatusResume, ShortVacancy } from 'src/models/applicantVacancyResponseStatuses';

import ResumeAvatar from 'src/components/VacancyResponseForm/ResumeAvatar';
import ResumeDetail from 'src/components/VacancyResponseForm/ResumeDetail';

interface ResumeSelectorV2Props {
    responseStatus: ShortVacancy;
    resumes: ResponseStatusResume[];
    selectedResume: ResponseStatusResume;
    setSelectedResume: (value: ResponseStatusResume) => void;
}

const TrlKeys = {
    titleResumes: 'vacancy.response.popup.resumes',
    hiddenResumes: 'vacancy.response.popup.hidden.resumes',
};

interface ResumeItem extends StaticDataFetcherItem {
    data: ResponseStatusResume;
}

const ResumeSelectorV2: TranslatedComponent<ResumeSelectorV2Props> = ({
    trls,
    responseStatus,
    selectedResume,
    resumes,
    setSelectedResume,
}) => {
    const hasHiddenResumes = responseStatus.hiddenResumeIds.length > 0;
    const canSelectResume = resumes.length > 1;
    const selectedResumeTitle = selectedResume?.title?.[0]?.string;

    const renderSelectedResume = (
        isSelectExpanded?: boolean,
        onSelectChange?: (isExpanded: boolean) => void,
        selectRef?: RefObject<HTMLDivElement>
    ) => {
        const handleClick = () => {
            if (!canSelectResume) {
                return;
            }

            if (typeof onSelectChange === 'function') {
                onSelectChange(!isSelectExpanded);
            }
        };

        let icon;
        if (canSelectResume) {
            icon = isSelectExpanded ? <ChevronUpOutlinedSize24 /> : <ChevronDownOutlinedSize24 />;
        }

        return (
            <Card ref={selectRef} stretched borderWidth="default" padding={16} borderRadius={16} onClick={handleClick}>
                <Cell avatar={<ResumeAvatar resume={selectedResume} />} right={icon}>
                    <CellText type="title" maxLines={2} data-qa="resume-title">
                        {selectedResumeTitle}
                    </CellText>
                    <CellText type="subtitle" maxLines={1} data-qa="resume-detail">
                        <ResumeDetail resume={selectedResume} />
                    </CellText>
                </Cell>
            </Card>
        );
    };

    const handleSelectedItem = useCallback(
        (selectedItem: ResumeItem) => {
            const nextSelectedResume = resumes.find((resume) => resume.hash === selectedItem.value);
            if (nextSelectedResume) {
                setSelectedResume(nextSelectedResume);
            }
        },
        [resumes, setSelectedResume]
    );

    const selectItems: ResumeItem[] = resumes.map((resume) => ({
        value: resume.hash,
        data: resume,
    }));

    const provider = useStaticDataProvider(selectItems);

    const renderSelect = () => {
        if (!canSelectResume) {
            return renderSelectedResume();
        }

        const renderSelectItem: RenderOptionType<ResumeItem> = ({ input, data }) => (
            <Cell left={input} avatar={<ResumeAvatar resume={data.data} />}>
                <CellText type="title" maxLines={2} data-qa="resume-title">
                    {data.data?.title?.[0]?.string ?? ''}
                </CellText>
                <CellText type="subtitle" maxLines={1} data-qa="resume-detail">
                    <ResumeDetail resume={data.data} />
                </CellText>
            </Cell>
        );

        return (
            <Select
                type="radio"
                value={findStaticDataFetcherItemByValue(selectedResume.hash, selectItems)}
                renderTrigger={({ expanded, onChange, ref }) =>
                    renderSelectedResume(expanded, onChange, ref as RefObject<HTMLDivElement>)
                }
                triggerProps={{
                    size: 'medium',
                }}
                renderDesktopItem={renderSelectItem}
                renderMobileItem={renderSelectItem}
                widthEqualToActivator
                dataProvider={provider}
                onChange={handleSelectedItem}
                renderHeader={({ onCloseBottomSheet }) => (
                    <NavigationBar
                        title={trls[TrlKeys.titleResumes]}
                        right={<Action icon={CrossOutlinedSize24} onClick={onCloseBottomSheet} />}
                    />
                )}
            />
        );
    };

    return (
        <VSpacingContainer default={12}>
            {hasHiddenResumes && <Text typography="label-3-regular">{trls[TrlKeys.hiddenResumes]}</Text>}
            {renderSelect()}
        </VSpacingContainer>
    );
};

export default translation(ResumeSelectorV2);
