import { FC } from 'react';

import FormatMoney from 'src/components/FormatMoney';
import { type ResponseStatusResume } from 'src/models/applicantVacancyResponseStatuses';

interface ResumeDetailProps {
    resume: ResponseStatusResume;
}

const ResumeDetail: FC<ResumeDetailProps> = ({ resume }) => {
    const salary = resume?.salary?.[0];

    return salary ? <FormatMoney currency={salary.currency}>{salary.amount}</FormatMoney> : null;
};

export default ResumeDetail;
