import { useEffect } from 'react';

import Analytics from '@hh.ru/analytics-js';
import vacancyResponseAlertPrimaryButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/response/vacancy_response_alert_primary_button_click';
import vacancyResponseAlertScreenShown from '@hh.ru/analytics-js-events/build/xhh/applicant/response/vacancy_response_alert_screen_shown';
import vacancyResponseAlertSecondaryButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/response/vacancy_response_alert_secondary_button_click';
import vacancyResponseCompleteExternal from '@hh.ru/analytics-js-events/build/xhh/applicant/vacancy/vacancy_response_complete';
import vacancyResponseCompleteFeedExternal from '@hh.ru/analytics-js-events/build/xhh/applicant/vacancy/vacancy_response_complete_feed';
import { Alert, Button as MagritteButton } from '@hh.ru/magritte-ui';
import { ExclamationCircleFilledSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import SendHHAnalytics from 'HH/SendHHAnalytics';
import Debug from 'HHC/Debug';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { useIsHhru } from 'src/hooks/useSites';

export const sendAdvertisingAnalytics = (vacancyId: number): void => {
    SendHHAnalytics({ data: { goal: 'vacancy-response-company-url' } }).catch(console.error);

    const externalEventParams = { params: { product_id: vacancyId } };
    vacancyResponseCompleteExternal(externalEventParams);
    vacancyResponseCompleteFeedExternal(externalEventParams);
    // tempexp_32623_next-line
    Analytics.sendEvent('b2c', 'response', 'beacon_test');
};

const TrlKeys = {
    advertisingTitle: 'vacancy.advertising.title',
    advertisingText: 'vacancy.advertising.text',
    advertisingAction: 'vacancy.advertising.goforward',
    advertisingCancel: 'vacancy.advertising.cancel',
};

interface AdvertisingPopupProps {
    visible: boolean;
    onClose: (isCancel?: boolean) => void;
}

const ALERT_NAME = 'vacancy_response_vr_alert';

const AdvertisingPopup: TranslatedComponent<AdvertisingPopupProps> = ({ visible, onClose, trls }) => {
    const vacancyId = useSelector((state) => state.vacancyResponsePopup?.vacancy?.vacancyId ?? 0);
    const companyUrl = useSelector((state) => state.vacancyResponsePopup?.vacancy?.companyUrl ?? '');
    const proceedTrl = trls[TrlKeys.advertisingAction];
    const dismissTrl = trls[TrlKeys.advertisingCancel];
    const isHhRu = useIsHhru();

    useEffect(() => {
        const isAlertVisible = visible && Boolean(vacancyId) && Boolean(companyUrl);

        if (isAlertVisible) {
            vacancyResponseAlertScreenShown({
                vacancyId: `${vacancyId}`,
                alertName: ALERT_NAME,
                hhtmSource: ALERT_NAME,
            });
        }
    }, [visible, vacancyId, companyUrl]);

    if (!companyUrl) {
        Debug.log('out error', new Error('Advertising vacancy without companyUrl'), { id: vacancyId });
        return null;
    }

    const proceed = () => {
        vacancyResponseAlertPrimaryButtonClick({
            buttonText: proceedTrl,
            vacancyId: `${vacancyId}`,
            alertName: ALERT_NAME,
            hhtmSource: ALERT_NAME,
        });

        sendAdvertisingAnalytics(vacancyId);

        if (isHhRu) {
            Analytics.addEventPixels('B2C_VACANCY_RESPONSE_COMPLETE');
        }
    };

    const dismiss = () => {
        onClose(true);
    };

    const dismissByButton = () => {
        vacancyResponseAlertSecondaryButtonClick({
            buttonText: dismissTrl,
            vacancyId: `${vacancyId}`,
            alertName: ALERT_NAME,
            hhtmSource: ALERT_NAME,
        });

        dismiss();
    };

    return (
        <Alert
            visible={visible}
            title={trls[TrlKeys.advertisingTitle]}
            description={trls[TrlKeys.advertisingText]}
            layout="vertical"
            icon={<ExclamationCircleFilledSize24 />}
            buttons={
                <>
                    <MagritteButton
                        Element="a"
                        rel="nofollow"
                        href={companyUrl}
                        mode="primary"
                        style="accent"
                        data-qa="vacancy-response-link-advertising"
                        onClick={proceed}
                    >
                        {proceedTrl}
                    </MagritteButton>
                    <MagritteButton
                        mode="secondary"
                        style="accent"
                        data-qa="vacancy-response-link-advertising-cancel"
                        onClick={dismissByButton}
                    >
                        {dismissTrl}
                    </MagritteButton>
                </>
            }
            onClose={dismiss}
        />
    );
};

export default translation(AdvertisingPopup);
