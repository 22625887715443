import { useElementShown } from '@hh.ru/analytics-js';
import responseSentElementShown from '@hh.ru/analytics-js-events/build/xhh/applicant/user_status/after_response/response_sent_element_shown';
import { Text } from '@hh.ru/magritte-ui';
import { CheckCircleFilledSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

import Notification from 'src/components/Notifications/Notification';
import { NotificationProps } from 'src/components/Notifications/Provider/types';

interface VacancyResponseStandardNotificationProps {
    vacancyId: number;
}

const TrlKeys = {
    text: 'notification.applicant.response.standard.title',
};

const VacancyResponseStandardNotification: TranslatedComponent<
    VacancyResponseStandardNotificationProps & NotificationProps
> = ({ trls, removeNotification, vacancyId }) => {
    const notificationRef = useElementShown(responseSentElementShown, { vacancyId });

    return (
        <Notification
            removeNotification={removeNotification}
            addon={<CheckCircleFilledSize24 initialColor="positive" />}
            onAction={removeNotification}
            data-qa="vacancy-response-success-standard-notification"
        >
            <div ref={notificationRef}>
                <Text style="contrast" typography="label-2-regular">
                    {trls[TrlKeys.text]}
                </Text>
            </div>
        </Notification>
    );
};

export default translation(VacancyResponseStandardNotification);
